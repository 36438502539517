import store from '../store';

const getSideMenu = function () {
    const user = store.getters.currentUser;

    const commonMenu = [];

    const adminMenu = [
        {
            icon: 'fa fa-list-alt',
            text: 'Interview Templates',
            link: '/admin/interview-templates/'
        },
        {
            icon: 'fa fa-list-alt',
            text: 'Attended Interviews',
            link: '/admin/attended-interviews/'
        },
        {
            icon: 'fa fa-list-alt',
            text: 'User Role',
            link: '/admin/user-role/'
        },
        {
            icon: 'fa fa-list-alt',
            text: 'MCQ Interviews',
            link: '/admin/mcq-interviews/'
        }
    ];

    const mcqMenu = [
        {
            icon: 'fa fa-list-alt',
            text: 'MCQ Interviews',
            link: '/admin/mcq-interviews/'
        }
    ];

    const userMenu = [
        {
            icon: 'fa fa-list',
            text: 'Interviews',
            link: '/user/interviews/'
        },
        {
            icon: 'fa fa-list',
            text: 'Attended Interviews',
            link: '/user/attended-interviews/'
        }
    ];
    if (user.is_superuser) {
        return [
            ...adminMenu, ...commonMenu
        ];
    } else if (user.is_interviewee) {
        return [];
    } else if (user.is_recruitment) {
        return [
            ...mcqMenu, ...userMenu, ...commonMenu
        ];
    } else {
        return [
            ...userMenu, ...commonMenu
        ];
    }
};

export default getSideMenu;
